/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ----------------------------------------------------------------------

// GET
export const getFactorApi = ({ page, count }) => ({
  method: 'get',
  url: HOST_API_KEY + `/factors?page=${page}&count=${count}`,
});

export const getFactorByIdApi = ({ factorId }) => ({
  method: 'get',
  url: HOST_API_KEY + `/factors/admin/${factorId}`,
});

export const getPaymentReqApi = () => ({
  method: 'get',
  url: HOST_API_KEY + '/factors/requests',
});

export const getRejectPaymentApi = ({ factorId }) => ({
  method: 'get',
  url: HOST_API_KEY + `/factors/reject-payment/${factorId}`,
});

// PATCH
export const editFactorApi = ({ factorId, ...updateFactorData }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/factors/admin/${factorId}`,
  data: updateFactorData,
});

export const acceptPaymentApi = ({ factorId, ...acceptData }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/factors/accept-payment/${factorId}`,
  data: acceptData,
});

export const changeFactorStatusApi = ({ factorId, ...factorStatusData }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/factors/status/${factorId}`,
  data: factorStatusData,
});

/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
import request from 'src/utils/request';
// api
import { getProductApi, getProductsApi, postNewProductApi, editProductApi, deleteProductApi } from 'src/api/products';
import useRequest from 'src/hooks/useRequest';

// ----------------------------------------------------------------------

const initialState = {
  products: XHR_PAYLOADS.idle(),
  product: XHR_PAYLOADS.idle(),
};

const reducer = (state, action) => {
  if (action.type === 'SET_PRODUCTS') {
    return {
      ...state,
      products: action.payload,
    };
  }
  if (action.type === 'SET_PRODUCT') {
    return {
      ...state,
      product: action.payload,
    };
  }

  return state;
};

const ProductContext = createContext({
  initialState,
});

ProductProvider.propTypes = {
  children: PropTypes.node,
};

function ProductProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getProducts({ page: 0, count: 5 });
  }, []);

  const getProducts = async ({ page, count }) => {
    request('SET_PRODUCTS', getProductsApi({ page, count }))(dispatch);
  };

  const getProductById = async ({ productId }) => {
    request('SET_PRODUCT', getProductApi({ productId }))(dispatch);
  };

  const postNewProduct = useRequest(postNewProductApi, () => {
    getProducts({ page: 0, count: 5 });
  });

  const editProduct = useRequest(editProductApi, () => {
    getProducts({ page: 0, count: 5 });
  });

  const deleteProduct = useRequest(deleteProductApi, () => {
    getProducts({ page: 0, count: 5 });
  });

  return (
    <ProductContext.Provider
      value={{ ...state, getProducts, getProductById, postNewProduct, editProduct, deleteProduct }}
    >
      {children}
    </ProductContext.Provider>
  );
}

export { ProductContext, ProductProvider };

/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
import request from 'src/utils/request';
// api
import { getAllJobRequestApi, doneReqJobApi, rejectReqJobApi, deleteReqJobApi } from 'src/api/JobReq';
import useRequest from 'src/hooks/useRequest';

// ----------------------------------------------------------------------

const initialState = {
  reqJobs: XHR_PAYLOADS.idle(),
};

const reducer = (state, action) => {
  if (action.type === 'SET_REQ_JOBS') {
    return {
      ...state,
      reqJobs: action.payload,
    };
  }

  return state;
};

const JobRequestContext = createContext({ initialState });

JobRequestProvider.propTypes = {
  children: PropTypes.node,
};

function JobRequestProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getAllJobRequest({ page: 0, count: 5 });
  }, []);

  const getAllJobRequest = async ({ page, count }) => {
    request('SET_REQ_JOBS', getAllJobRequestApi({ page, count }))(dispatch);
  };

  const doneRequest = useRequest(doneReqJobApi, () => {
    getAllJobRequest({ page: 0, count: 5 });
  });

  const rejectRequest = useRequest(rejectReqJobApi, () => {
    getAllJobRequest({ page: 0, count: 5 });
  });

  const deleteRequest = useRequest(deleteReqJobApi, () => {
    getAllJobRequest({ page: 0, count: 5 });
  });

  return (
    <JobRequestContext.Provider value={{ ...state, getAllJobRequest, doneRequest, rejectRequest, deleteRequest }}>
      {children}
    </JobRequestContext.Provider>
  );
}
export { JobRequestContext, JobRequestProvider };

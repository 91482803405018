import { useContext } from 'react';
//
// eslint-disable-next-line import/no-unresolved
import { AccountContext } from 'src/contexts/AccountContext';

// ----------------------------------------------------------------------

export const useAccountContext = () => {
  const context = useContext(AccountContext);

  if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

  return context;
};

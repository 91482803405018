/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ------------------------------------------------

// GET
export const getProductsApi = ({ page, count }) => ({
  method: 'get',
  url: HOST_API_KEY + `/products?page=${page}&count=${count}`,
});

export const getProductApi = ({ productId }) => ({
  method: 'get',
  url: HOST_API_KEY + `/products/${productId}`,
});

export const getProductByCategoryApi = ({ page, count, categoryId }) => ({
  method: 'get',
  url: HOST_API_KEY + `/products/category/${categoryId}?page=${page}&count=${count}`,
});

// POST
export const postNewProductApi = (productData) => ({
  method: 'post',
  url: HOST_API_KEY + '/products',
  data: productData,
});

// PATCH
export const editProductApi = ({ productID, ...updateProductData }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/products/${productID}`,
  data: updateProductData,
});

// DELETE
export const deleteProductApi = ({ productId }) => ({
  method: 'delete',
  url: HOST_API_KEY + `/products/${productId}`,
});

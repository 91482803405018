/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
import request from 'src/utils/request';
// api
import { getAllEstimateApi, doneEstimateApi, deleteEstimateApi } from 'src/api/estimate';
import useRequest from 'src/hooks/useRequest';

// ----------------------------------------------------------------------

const initialState = {
  estimates: XHR_PAYLOADS.idle(),
};

const reducer = (state, action) => {
  if (action.type === 'SET_ESTIMATES') {
    return {
      ...state,
      estimates: action.payload,
    };
  }

  return state;
};

const EstimateContext = createContext({ initialState });

EstimateProvider.propTypes = {
  children: PropTypes.node,
};

function EstimateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getAllEstimate({ page: 0, count: 5 });
  }, []);

  const getAllEstimate = async ({ page, count }) => {
    request('SET_ESTIMATES', getAllEstimateApi({ page, count }))(dispatch);
  };

  const doneEstimate = useRequest(doneEstimateApi, () => {
    getAllEstimate({ page: 0, count: 5 });
  });

  const deleteEstimate = useRequest(deleteEstimateApi, () => {
    getAllEstimate({ page: 0, count: 5 });
  });

  return (
    <EstimateContext.Provider value={{ ...state, getAllEstimate, doneEstimate, deleteEstimate }}>
      {children}
    </EstimateContext.Provider>
  );
}
export { EstimateContext, EstimateProvider };

/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ------------------------------------------------

export const postLoginApi = ({ email, password }) => ({
  method: 'post',
  url: HOST_API_KEY + '/auth/email/login',
  data: {
    email,
    password,
  },
});

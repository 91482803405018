/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ----------------------------------------------------------------------

// GET
export const getAllEstimateApi = ({ page, count }) => ({
  method: 'get',
  url: HOST_API_KEY + `/estimations?page=${page}&count=${count}`,
});

// PATCH
export const doneEstimateApi = ({ estimateId }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/estimations/${estimateId}`,
  data: {
    isDone: true,
  },
});

// DELETE
export const deleteEstimateApi = ({ estimateId }) => ({
  method: 'delete',
  url: HOST_API_KEY + `/estimations/${estimateId}`,
});

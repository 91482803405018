/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
import request from 'src/utils/request';
// api
import { getAllUserApi, editUserRoleApi, deleteUserApi } from 'src/api/user';
// hook
import { useAuthContext } from 'src/hooks/useAuthContext';
import useRequest from 'src/hooks/useRequest';

// ----------------------------------------------------------------------

const initialState = {
  users: XHR_PAYLOADS.idle(),
};

const handlers = {
  GET_USERS: (state, action) => ({
    ...state,
    users: action.payload,
  }),
  CLEAR_USERS: () => initialState,
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const UserContext = createContext({
  ...initialState,
});

UserProvider.propTypes = {
  children: PropTypes.node,
};

function UserProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated && !state.users.data) {
      getAllUser({ page: 0, count: 5 });
    }
  }, [isAuthenticated]);

  const getAllUser = async (values) => {
    request('GET_USERS', getAllUserApi(values))(dispatch);
  };

  const editUserRole = useRequest(editUserRoleApi, () => {
    getAllUser({ page: 0, count: 5 });
  });

  const deleteUser = useRequest(deleteUserApi, () => {
    getAllUser({ page: 0, count: 5 })
  })

  return (
    <UserContext.Provider
      value={{
        ...state,
        getAllUser,
        editUserRole,
        deleteUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };

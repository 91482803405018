/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ------------------------------------------------

// GET
export const getUserInfoApi = () => ({
  method: 'get',
  url: HOST_API_KEY + '/auth/my_info',
});

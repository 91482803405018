// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: icon('ic_user'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'admin',
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.user,
        children: [
          { title: 'Category', path: PATH_DASHBOARD.admin.category.category },
          { title: 'Products', path: PATH_DASHBOARD.admin.products.products },
          { title: 'Solutions', path: PATH_DASHBOARD.admin.solutions.solutions },
          { title: 'Gallery', path: PATH_DASHBOARD.admin.gallery.gallery },
          { title: 'Orders', path: PATH_DASHBOARD.admin.factor.factor },
          { title: 'Users', path: PATH_DASHBOARD.admin.users.users },
          { title: 'Meetings', path: PATH_DASHBOARD.admin.meetings.meetings },
          { title: 'Contact Us', path: PATH_DASHBOARD.admin.contactUs.contactUs },
          { title: 'Job Request', path: PATH_DASHBOARD.admin.jobRequest.jobRequest },
          { title: 'Estimate', path: PATH_DASHBOARD.admin.estimate.estimate },
        ],
      },
    ],
  },
];

export default navConfig;

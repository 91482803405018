/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
import request from 'src/utils/request';
import useRequest from 'src/hooks/useRequest';
// api
import { createCategoryApi, getActiveCategoriesApi, editCategoryApi, deleteCategoryApi } from 'src/api/category';

// ----------------------------------------------------------------------

const initialState = {
  categories: XHR_PAYLOADS.idle(),
};

const reducer = (state, action) => {
  if (action.type === 'SET_CATEGORIES') {
    return {
      ...state,
      categories: action.payload,
    };
  }

  return state;
};

const CategoryContext = createContext({ ...initialState });

CategoryProvider.propTypes = {
  children: PropTypes.node,
};

function CategoryProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getActiveCategory();
  }, []);

  const getActiveCategory = async () => {
    request('SET_CATEGORIES', getActiveCategoriesApi())(dispatch);
  };

  const postNewCategory = useRequest(createCategoryApi, () => {
    getActiveCategory();
  });

  const editCategory = useRequest(editCategoryApi, () => {
    getActiveCategory();
  });

  const deleteCategory = useRequest(deleteCategoryApi);

  return (
    <CategoryContext.Provider value={{ ...state, getActiveCategory, postNewCategory, editCategory, deleteCategory }}>
      {children}
    </CategoryContext.Provider>
  );
}

export { CategoryContext, CategoryProvider };

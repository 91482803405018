// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// eslint-disable-next-line import/no-unresolved
// components
import { SettingsProvider } from './components/settings';
import ScrollToTop from './components/scroll-to-top';

// Check our docs
import { AuthProvider } from './contexts/JwtContext';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ProductProvider } from './contexts/ProductContext';
import { AccountProvider } from './contexts/AccountContext';
import { CategoryProvider } from './contexts/CategoryContext';
import { FileProvider } from './contexts/FileContext';
import { SolutionProvider } from './contexts/SolutionContext';
import { GalleryProvider } from './contexts/GalleryContext';
import { FactorProvider } from './contexts/FactorContext';
import { UserProvider } from './contexts/UserContext';
import { ContactUsProvider } from './contexts/ContactUsContext';
import { JobRequestProvider } from './contexts/JobRequestContext';
import { EstimateProvider } from './contexts/EstimateContext';
import { MeetingProvider } from './contexts/MeetingContext';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <AccountProvider>
      <CategoryProvider>
        <ProductProvider>
          <SolutionProvider>
            <FileProvider>
              <FactorProvider>
                <GalleryProvider>
                  <UserProvider>
                    <HelmetProvider>
                      <SettingsProvider>
                        <MeetingProvider>
                          <ContactUsProvider>
                            <JobRequestProvider>
                              <EstimateProvider>
                                <BrowserRouter>
                                  <ScrollToTop />
                                  <App />
                                </BrowserRouter>
                              </EstimateProvider>
                            </JobRequestProvider>
                          </ContactUsProvider>
                        </MeetingProvider>
                      </SettingsProvider>
                    </HelmetProvider>
                  </UserProvider>
                </GalleryProvider>
              </FactorProvider>
            </FileProvider>
          </SolutionProvider>
        </ProductProvider>
      </CategoryProvider>
    </AccountProvider>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
import request from 'src/utils/request';
// api
import {
  getSolutionsApi,
  getSolutionApi,
  createSolutionApi,
  editSolutionApi,
  deleteSolutionApi,
} from 'src/api/solutions';
import useRequest from 'src/hooks/useRequest';

// ----------------------------------------------------------------------

const initialState = {
  solutions: XHR_PAYLOADS.idle(),
  solution: XHR_PAYLOADS.idle(),
};

const reducer = (state, action) => {
  if (action.type === 'SET_SOLUTIONS') {
    return {
      ...state,
      solutions: action.payload,
    };
  }
  if (action.type === 'SET_SOLUTION') {
    return {
      ...state,
      solution: action.payload,
    };
  }

  return state;
};

const SolutionContext = createContext({ initialState });

SolutionProvider.propTypes = {
  children: PropTypes.node,
};

function SolutionProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getSolutions({ page: 0, count: 5 });
  }, []);

  const getSolutions = async ({ page, count }) => {
    request('SET_SOLUTIONS', getSolutionsApi({ page, count }))(dispatch);
  };

  const getSolutionById = async ({ solutionId }) => {
    request('SET_SOLUTION', getSolutionApi({ solutionId }))(dispatch);
  };

  const postNewSolution = useRequest(createSolutionApi, () => {
    getSolutions({ page: 0, count: 5 });
  });

  const editSolution = useRequest(editSolutionApi, () => {
    getSolutions({ page: 0, count: 5 });
  });

  const deleteSolution = useRequest(deleteSolutionApi, () => {
    getSolutions({ page: 0, count: 5 });
  });

  return (
    <SolutionContext.Provider
      value={{ ...state, getSolutions, getSolutionById, postNewSolution, editSolution, deleteSolution }}
    >
      {children}
    </SolutionContext.Provider>
  );
}
export { SolutionContext, SolutionProvider };

/* eslint-disable import/no-unresolved */
import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
// api
import { postFileApi } from 'src/api/file';
import useRequest from 'src/hooks/useRequest';

// ----------------------------------------------------------------------

const initialState = {
  postFileData: XHR_PAYLOADS.idle(),
};

const reducer = (state, action) => {
  if (action.type === 'POST_FILE') {
    return {
      ...state,
      postFileData: action.payload,
    };
  }
  if (action.type === 'CLEAR') {
    return {
      ...initialState,
    };
  }

  return state;
};

const FileContext = createContext({
  initialState,
});

FileProvider.propTypes = {
  children: PropTypes.node,
};

function FileProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const postFile = useRequest(postFileApi, (e) =>
    dispatch({
      type: 'POST_FILE',
      payload: e,
    })
  );

  const clearFile = () => {
    dispatch({
      type: 'CLEAR',
    });
  };

  return <FileContext.Provider value={{ ...state, postFile, clearFile }}>{children}</FileContext.Provider>;
}

export { FileContext, FileProvider };

/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ------------------------------------------------

// GET
export const getAllGalleryApi = ({ page, count }) => ({
  method: 'get',
  url: HOST_API_KEY + `/gallery?page=${page}&count=${count}`,
});

export const getOneGalleryApi = ({ galleryId }) => ({
  method: 'get',
  url: HOST_API_KEY + `/gallery/${galleryId}`,
});

// POST
export const postNewGalleryApi = (galleryData) => ({
  method: 'post',
  url: HOST_API_KEY + '/gallery',
  data: galleryData,
});

// PATCH
export const editGalleryApi = ({ galleryId, ...updateGalleryData }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/gallery/${galleryId}`,
  data: updateGalleryData,
});

// DELETE
export const deleteGalleryApi = ({ galleryId }) => ({
  method: 'delete',
  url: HOST_API_KEY + `/gallery/${galleryId}`,
});

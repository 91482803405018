/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ------------------------------------------------

// GET
export const getSolutionsApi = ({ page, count }) => ({
  method: 'get',
  url: HOST_API_KEY + `/solutions/?page=${page}&count=${count}`,
});

export const getSolutionApi = ({ solutionId }) => ({
  method: 'get',
  url: HOST_API_KEY + `/solutions/${solutionId}`,
});

// POST
export const createSolutionApi = (solutionData) => ({
  method: 'post',
  url: HOST_API_KEY + '/solutions',
  data: solutionData,
});

// PATCH
export const editSolutionApi = ({ solutionId, ...updateSolutionData }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/solutions/${solutionId}`,
  data: updateSolutionData,
});

// DELETE
export const deleteSolutionApi = ({ solutionId }) => ({
  method: 'delete',
  url: HOST_API_KEY + `/solutions/${solutionId}`,
});

/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
// import regeneratorRuntime from 'regenerator-runtime'

import axios, { XHR_PAYLOADS } from './axios';

const withDispatch = (type, config) => async (dispatch) => {
  dispatch({ type, payload: XHR_PAYLOADS.pending() });
  await axios({ ...config })
    .then((res) => dispatch({ type, payload: res }))
    .catch((error) => dispatch({ type, payload: error }));
};

export default withDispatch;

/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ------------------------------------------------

// GTE
export const getAllMeetingApi = ({ page, count, filterDate }) => ({
  method: 'get',
  url: HOST_API_KEY + `/meetings?page=${page}&count=${count}` + (filterDate ? `&date=${filterDate}` : ''),
});

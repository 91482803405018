/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ------------------------------------------------

// GET
export const getActiveCategoriesApi = () => ({
  method: 'get',
  url: HOST_API_KEY + '/categories',
});

export const getCategoryTreeApi = ({ categoryId }) => ({
  method: 'get',
  url: HOST_API_KEY + `/categories/tree/${categoryId}`,
});

export const getSubCategoryApi = ({ categoryId }) => ({
  method: 'get',
  url: HOST_API_KEY + `/categories/sub-category/${categoryId}`,
});

// POST
export const createCategoryApi = (categoryData) => ({
  method: 'post',
  url: HOST_API_KEY + '/categories',
  data: categoryData,
});

// PATCH
export const editCategoryApi = ({ name, categoryId }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/categories/${categoryId}`,
  data: {
    name,
  },
});

// DELETE
export const deleteCategoryApi = ({ categoryId }) => ({
  method: 'delete',
  url: HOST_API_KEY + `/categories/${categoryId}`,
});

/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_FILE_API_KEY } from 'src/config';

// ------------------------------------------------

// POST
export const postFileApi = ({ file }) => ({
  method: 'post',
  url: HOST_FILE_API_KEY + '/file',
  data: file,
});

/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ------------------------------------------------

// GTE
export const getAllJobRequestApi = ({ page, count }) => ({
  method: 'get',
  url: HOST_API_KEY + `/job-request/whitout-filter?page=${page}&count=${count}`,
});

// PATCH
export const doneReqJobApi = ({ requestId }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/job-request/${requestId}`,
  data: {
    isDone: true,
  },
});

export const rejectReqJobApi = ({ requestId }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/job-request/${requestId}`,
  data: {
    isRejected: true,
  },
});

// DELETE
export const deleteReqJobApi = ({ requestId }) => ({
  method: 'delete',
  url: HOST_API_KEY + `/job-request/${requestId}`,
});

/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ------------------------------------------------

// GET
export const getAllContactApi = ({ page, count }) => ({
  method: 'get',
  url: HOST_API_KEY + `/contact-us?page=${page}&count=${count}`,
});

// PATCH
export const changeContactApi = ({ contactID, ...contactDataUpdate }) => ({
  method: 'patch',
  url: HOST_API_KEY + `/contact-us/${contactID}`,
  data: contactDataUpdate,
});

/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
import request from 'src/utils/request';
import useRequest from 'src/hooks/useRequest';
// api
import {
  getFactorApi,
  getFactorByIdApi,
  getPaymentReqApi,
  getRejectPaymentApi,
  editFactorApi,
  acceptPaymentApi,
  changeFactorStatusApi,
} from 'src/api/factor';

// ----------------------------------------------------------------------

const initialState = {
  factors: XHR_PAYLOADS.idle(),
  factor: XHR_PAYLOADS.idle(),
  paymentReq: XHR_PAYLOADS.idle(),
  rejectPayment: XHR_PAYLOADS.idle(),
};

const reducer = (state, action) => {
  if (action.type === 'SET_FACTORS') {
    return {
      ...state,
      factors: action.payload,
    };
  }
  if (action.type === 'SET_FACTOR') {
    return {
      ...state,
      factor: action.payload,
    };
  }
  if (action.type === 'SET_PAYMENT_REQ') {
    return {
      ...state,
      paymentReq: action.payload,
    };
  }
  if (action.type === 'SET_REJECT_PAYMENT') {
    return {
      ...state,
      rejectPayment: action.payload,
    };
  }

  return state;
};

const FactorContext = createContext({ ...initialState });

FactorProvider.propTypes = {
  children: PropTypes.node,
};

function FactorProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getAllFactor();
  }, []);

  const getAllFactor = async (values) => {
    request('SET_FACTORS', getFactorApi(values))(dispatch);
  };

  const getFactorById = async (values) => {
    request('SET_FACTOR', getFactorByIdApi(values))(dispatch);
  };

  const getPaymentReq = async () => {
    request('SET_PAYMENT_REQ', getPaymentReqApi())(dispatch);
  };

  const getRejectPayment = async (value) => {
    request('SET_REJECT_PAYMENT', getRejectPaymentApi(value))(dispatch);
  };

  const editFactor = useRequest(editFactorApi, () => {
    getAllFactor({ page: 0, count: 5 });
  });

  const acceptPayment = useRequest(acceptPaymentApi, () => {
    getAllFactor({ page: 0, count: 5 });
  });

  const changeFactorStatus = useRequest(changeFactorStatusApi, () => {
    getAllFactor({ page: 0, count: 5 });
  });

  return (
    <FactorContext.Provider
      value={{
        ...state,
        getAllFactor,
        getFactorById,
        getPaymentReq,
        getRejectPayment,
        editFactor,
        acceptPayment,
        changeFactorStatus,
      }}
    >
      {children}
    </FactorContext.Provider>
  );
}

export { FactorContext, FactorProvider };

/* eslint-disable prefer-template */
// eslint-disable-next-line import/no-unresolved
import { HOST_API_KEY } from 'src/config';

// ------------------------------------------------

// GET
export const getAllUserApi = ({ page, count }) => ({
  method: 'get',
  url: HOST_API_KEY + `/users?page=${page}&count=${count}`,
});

// PATCH
export const editUserRoleApi = (userDataUpdate) => ({
  method: 'patch',
  url: HOST_API_KEY + `/users/roles`,
  data: userDataUpdate,
});

// DELETE
export const deleteUserApi = (userDataUpdate) => ({
  method: 'post',
  url: HOST_API_KEY + `/users/deactivate`,
  data: userDataUpdate
});


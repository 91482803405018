/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
import request from 'src/utils/request';
// hooks
import { useAuthContext } from 'src/hooks/useAuthContext';
import useRequest from 'src/hooks/useRequest';
import { getAllContactApi, changeContactApi } from '../api/contactUs';

// ----------------------------------------------------------------------

const initialState = {
  contactForm: XHR_PAYLOADS.idle(),
};

const handlers = {
  GET_ALL_CONTACT: (state, action) => ({
    ...state,
    contactForm: action.payload,
  }),
  CLEAR_CONTACT: () => initialState,
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const ContactUsContext = createContext({
  ...initialState,
});

ContactUsProvider.propTypes = {
  children: PropTypes.node,
};

function ContactUsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated && !state.contactForm.data) {
      getAllContactUs({ page: 0, count: 5 });
    }
  }, [isAuthenticated]);

  const getAllContactUs = async (value) => {
    request('GET_ALL_CONTACT', getAllContactApi(value))(dispatch);
  };

  const changeContact = useRequest(changeContactApi, () => {
    getAllContactUs({ page: 0, count: 5 });
  });

  return (
    <ContactUsContext.Provider
      value={{
        ...state,
        getAllContactUs,
        changeContact,
      }}
    >
      {children}
    </ContactUsContext.Provider>
  );
}

export { ContactUsContext, ContactUsProvider };

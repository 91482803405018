import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import {
  Page404,
  PageOne,
  PageCategory,
  PageProducts,
  LoginPage,
  PageNewCategory,
  PageEditCategory,
  PageNewProducts,
  PageChooseProduct,
  PageEditProduct,
  PageSolutions,
  PageNewSolutions,
  PageChooseSolution,
  PageEditSolution,
  PageGallery,
  PageNewGallery,
  PageEditGallery,
  PageFactor,
  PageUsers,
  PageContactUs,
  PageJobRequest,
  PageEstimate,
  PageMeetings,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'one', element: <PageOne /> },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/dashboard/admin/category" replace />, index: true },
            { path: 'category', element: <PageCategory /> },
            { path: 'category/new', element: <PageNewCategory /> },
            { path: 'category/:name/edit', element: <PageEditCategory /> },
            { path: 'products', element: <PageProducts /> },
            { path: 'products/new', element: <PageNewProducts /> },
            { path: 'products/:id/choose', element: <PageChooseProduct /> },
            { path: 'products/:id/edit', element: <PageEditProduct /> },
            { path: 'solutions', element: <PageSolutions /> },
            { path: 'solutions/new', element: <PageNewSolutions /> },
            { path: 'solutions/:id/choose', element: <PageChooseSolution /> },
            { path: 'solutions/:id/edit', element: <PageEditSolution /> },
            { path: 'gallery', element: <PageGallery /> },
            { path: 'gallery/new', element: <PageNewGallery /> },
            { path: 'gallery/edit', element: <PageEditGallery /> },
            { path: 'factor', element: <PageFactor /> },
            { path: 'users', element: <PageUsers /> },
            { path: 'meetings', element: <PageMeetings /> },
            { path: 'contactUs', element: <PageContactUs /> },
            { path: 'jobRequest', element: <PageJobRequest /> },
            { path: 'estimate', element: <PageEstimate /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

/* eslint-disable no-unused-expressions */
import { useState } from 'react';

// utils
import axios, { XHR_PAYLOADS } from '../utils/axios';

// ----------------------------------------------------------------------

export default function useRequest(apiConfigure, onResolve, onReject, options = {}) {
  const [response, setResponse] = useState(XHR_PAYLOADS.idle());
  const { delayedResetS, preserveData, defaultParams } = options;
  const request = async (apiParams, onSuccess, onError) => {
    setResponse(!preserveData ? XHR_PAYLOADS.pending() : { ...XHR_PAYLOADS.pending(), data: response.data });
    try {
      const res = await axios(apiConfigure(apiParams || defaultParams));
      setResponse(res);
      onResolve && onResolve(res);
      onSuccess && onSuccess(res);
    } catch (err) {
      setResponse(err);
      onReject && onReject(err);
      onError && onError(err);
    }
    if (delayedResetS) {
      const to = setTimeout(() => {
        reset();
        return clearTimeout(to);
      }, delayedResetS * 1000);
    }
  };

  const reset = () => setResponse(XHR_PAYLOADS.idle());

  return { ...response, request, reset };
}

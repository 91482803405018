/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
import axios from 'axios';

// eslint-disable-next-line import/no-unresolved
import { XHR_PENDING, XHR_FULFILLED, XHR_REJECTED, XHR_IDLE, HOST_API_KEY } from 'src/config';

// ----------------------------------------------------------------------

const instance = axios.create({ baseURL: HOST_API_KEY });

instance.interceptors.response.use(
  (response) => reqFulfilledPayload(response.data, response.data.code),
  (error) => {
    let errorBody;
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      errorBody = {
        data: error.response.data,
        status: error.response.status,
      };
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      errorBody = {
        data: error.request,
        status: error.request.status,
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      errorBody = {
        data: error.message,
        status: error.response.status,
      };
    }
    return Promise.reject(reqRejectedPayload(errorBody.data, errorBody.status));
  }
);

export default instance;

const reqIdlePayload = () => ({
  status: XHR_IDLE,
  data: null,
  meta: {},
  error: null,
  isLoading: false,
  statusCode: null,
});

const reqPendingPayload = () => ({
  status: XHR_PENDING,
  data: null,
  meta: {},
  error: null,
  isLoading: true,
  statusCode: null,
});

const reqFulfilledPayload = (res, statusCode) => {
  const commonPayload = {
    status: XHR_FULFILLED,
    error: null,
    meta: {},
    isLoading: false,
    statusCode,
  };
  return {
    ...commonPayload,
    data: res.data,
  };
};

const reqRejectedPayload = (err, statusCode) => ({
  status: XHR_REJECTED,
  data: null,
  meta: {},
  error: err,
  isLoading: false,
  statusCode,
});

export const XHR_PAYLOADS = {
  idle: reqIdlePayload,
  pending: reqPendingPayload,
  fulfilled: reqFulfilledPayload,
  reject: reqRejectedPayload,
};

/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from '../utils/axios';
import request from '../utils/request';
// api
import { getAllMeetingApi } from '../api/meeting';
// hooks
import { useAuthContext } from '../hooks/useAuthContext';

// ----------------------------------------------------------------------

const initialState = {
  meetings: XHR_PAYLOADS.idle(),
};

const handlers = {
  SET_ALL_MEETINGS: (state, action) => ({
    ...state,
    meetings: action.payload,
  }),
  CLEAR_MEETINGS: () => initialState,
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const MeetingContext = createContext({
  ...initialState,
});

MeetingProvider.propTypes = {
  children: PropTypes.node,
};

function MeetingProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated && !state.meetings.data) {
      getAllMeeting({ page: 0, count: 5 });
    }
  }, [isAuthenticated]);

  const getAllMeeting = async (value) => {
    request('SET_ALL_MEETINGS', getAllMeetingApi(value))(dispatch);
  };

  return (
    <MeetingContext.Provider
      value={{
        ...state,
        getAllMeeting,
      }}
    >
      {children}
    </MeetingContext.Provider>
  );
}

export { MeetingContext, MeetingProvider };

/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
import request from 'src/utils/request';
// api
import {
  getAllGalleryApi,
  getOneGalleryApi,
  postNewGalleryApi,
  editGalleryApi,
  deleteGalleryApi,
} from 'src/api/gallery';
import useRequest from 'src/hooks/useRequest';

// ----------------------------------------------------------------------

const initialState = {
  galleries: XHR_PAYLOADS.idle(),
  gallery: XHR_PAYLOADS.idle(),
};

const reducer = (state, action) => {
  if (action.type === 'SET_GALLERIES') {
    return {
      ...state,
      galleries: action.payload,
    };
  }
  if (action.type === 'SET_GALLERY') {
    return {
      ...state,
      gallery: action.payload,
    };
  }

  return state;
};

const GalleryContext = createContext({ initialState });

GalleryProvider.propTypes = {
  children: PropTypes.node,
};

function GalleryProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getGalleries({ page: 0, count: 5 });
  }, []);

  const getGalleries = async ({ page, count }) => {
    request('SET_GALLERIES', getAllGalleryApi({ page, count }))(dispatch);
  };

  const getGalleryById = async ({ galleryId }) => {
    request('SET_GALLERY', getOneGalleryApi({ galleryId }))(dispatch);
  };

  const postNewGallery = useRequest(postNewGalleryApi, () => {
    getGalleries({ page: 0, count: 5 });
  });

  const editGallery = useRequest(editGalleryApi, () => {
    getGalleries({ page: 0, count: 5 });
  });

  const deleteGallery = useRequest(deleteGalleryApi, () => {
    getGalleries({ page: 0, count: 5 });
  });

  return (
    <GalleryContext.Provider
      value={{ ...state, getGalleries, getGalleryById, postNewGallery, editGallery, deleteGallery }}
    >
      {children}
    </GalleryContext.Provider>
  );
}
export { GalleryContext, GalleryProvider };

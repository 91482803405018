import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
export const PageCategory = Loadable(lazy(() => import('../pages/dashboard/PageCategory')));
export const PageNewCategory = Loadable(lazy(() => import('../pages/dashboard/PageNewCategory')));
export const PageEditCategory = Loadable(lazy(() => import('../pages/dashboard/PageEditCategory')));
export const PageProducts = Loadable(lazy(() => import('../pages/dashboard/PageProducts')));
export const PageNewProducts = Loadable(lazy(() => import('../pages/dashboard/PageNewProducts')));
export const PageChooseProduct = Loadable(lazy(() => import('../pages/dashboard/PageChooseProduct')));
export const PageEditProduct = Loadable(lazy(() => import('../pages/dashboard/PageEditProductItem')));
export const PageSolutions = Loadable(lazy(() => import('../pages/dashboard/PageSolutions')));
export const PageNewSolutions = Loadable(lazy(() => import('../pages/dashboard/PageNewSolutions')));
export const PageChooseSolution = Loadable(lazy(() => import('../pages/dashboard/PageChooseSolution')));
export const PageEditSolution = Loadable(lazy(() => import('../pages/dashboard/PageEditSolutionItem')));
export const PageGallery = Loadable(lazy(() => import('../pages/dashboard/PageGallery')));
export const PageNewGallery = Loadable(lazy(() => import('../pages/dashboard/PageNewGallery')));
export const PageEditGallery = Loadable(lazy(() => import('../pages/dashboard/PageEditGallery')));
export const PageFactor = Loadable(lazy(() => import('../pages/dashboard/PageFactor')));
export const PageUsers = Loadable(lazy(() => import('../pages/dashboard/PageUsers')));
export const PageContactUs = Loadable(lazy(() => import('../pages/dashboard/PageContactUs')));
export const PageJobRequest = Loadable(lazy(() => import('../pages/dashboard/PageJobRequest')));
export const PageEstimate = Loadable(lazy(() => import('../pages/dashboard/PageEstimate')));
export const PageMeetings = Loadable(lazy(() => import('../pages/dashboard/PageMeetings')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { XHR_PAYLOADS } from 'src/utils/axios';
import request from 'src/utils/request';
// api
import { getUserInfoApi } from 'src/api/account';
// hooks
import { useAuthContext } from 'src/hooks/useAuthContext';

// ----------------------------------------------------------------------

const initialState = {
  accounts: XHR_PAYLOADS.idle(),
};

const handlers = {
  GET_USER_ACCOUNTS: (state, action) => ({
    ...state,
    accounts: action.payload,
  }),
  CLEAR_ACCOUNTS: () => initialState,
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AccountContext = createContext({
  ...initialState,
});

AccountProvider.propTypes = {
  children: PropTypes.node,
};

function AccountProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated && !state.accounts.data) {
      getUserAccounts();
    }
  }, [isAuthenticated]);

  const getUserAccounts = async () => {
    request('GET_USER_ACCOUNTS', getUserInfoApi())(dispatch);
  };

  return (
    <AccountContext.Provider
      value={{
        ...state,
        getUserAccounts,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export { AccountContext, AccountProvider };

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  one: path(ROOTS_DASHBOARD, '/one'),
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    category: {
      category: path(ROOTS_DASHBOARD, '/admin/category'),
      newCategory: path(ROOTS_DASHBOARD, '/admin/category/new'),
      editCategory: (name) => path(ROOTS_DASHBOARD, `/admin/category/${name}/edit`),
    },
    products: {
      products: path(ROOTS_DASHBOARD, '/admin/products'),
      newProducts: path(ROOTS_DASHBOARD, '/admin/products/new'),
      chooseProduct: (id) => path(ROOTS_DASHBOARD, `/admin/products/${id}/choose`),
      editProductItem: (id) => path(ROOTS_DASHBOARD, `/admin/products/${id}/edit`),
    },
    solutions: {
      solutions: path(ROOTS_DASHBOARD, '/admin/solutions'),
      newSolution: path(ROOTS_DASHBOARD, '/admin/solutions/new'),
      chooseSolution: (id) => path(ROOTS_DASHBOARD, `/admin/solutions/${id}/choose`),
      editSolutionItem: (id) => path(ROOTS_DASHBOARD, `/admin/solutions/${id}/edit`),
    },
    gallery: {
      gallery: path(ROOTS_DASHBOARD, '/admin/gallery'),
      newGallery: path(ROOTS_DASHBOARD, '/admin/gallery/new'),
      editGallery: path(ROOTS_DASHBOARD, '/admin/gallery/edit'),
    },
    factor: {
      factor: path(ROOTS_DASHBOARD, '/admin/factor'),
    },
    users: {
      users: path(ROOTS_DASHBOARD, '/admin/users'),
    },
    meetings: {
      meetings: path(ROOTS_DASHBOARD, '/admin/meetings'),
    },
    contactUs: {
      contactUs: path(ROOTS_DASHBOARD, '/admin/contactUs'),
    },
    jobRequest: {
      jobRequest: path(ROOTS_DASHBOARD, '/admin/jobRequest'),
    },
    estimate: {
      estimate: path(ROOTS_DASHBOARD, '/admin/estimate'),
    },
  },
};
